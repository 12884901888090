import React from 'react';
import classNames from 'classnames';
import * as textTypes from '../typesQuestion';
import * as functions from 'components/functions';
import { Container, Card, CardBody, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';
import openNotificationWithIcon from 'components/Notification';
import NotData from 'components/Error/NotData';
import { Col, Row } from 'antd';


class Type04 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: [],
      exerciseCountdowns: []
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.question !== state.question) {

      // eslint-disable-next-line no-unused-vars
      let questions = functions.randomImageAnswersFromAPI(props.data);

      questions = functions.randomFourAnswersOneWay(questions);
      return {
        question: props.question,
      };
    }
    return null;
  };

  checkAnswer = (answer) => {
    const { question, onNext, questionIndex } = this.props;
    const { exerciseCountdowns } = this.state;
    let isDone = false;
    if (answer.id === question.id) {
      openNotificationWithIcon('success', 'CORRECT');
      isDone = true;
    } else {
      openNotificationWithIcon('danger', 'INCORRECT');
    }
    exerciseCountdowns.push({ isDone, questionIndex });
    const isPush = false;
    const postAnswerToApiParams = {
      notes: '',
      questionGuid: '',
      answerType: functions.getAnswerType(textTypes.Type04),
      studentChoice: JSON.stringify({
        id: answer ? answer.id : question.id,
        imageUrl: answer ? answer.imageUrl : ''
      }),
    }
    onNext(exerciseCountdowns, postAnswerToApiParams, isPush)
  };

  render() {
    const { question } = this.state;

    if (!question) {
      return <NotData />;
    }
    if (!question.answers) return null;
    return (
      <Container>
        <Card className='bg-gradient-info'>
          <Row justify='space-around' gutter={[12,12]} style={{padding:20, margin:'0px 0px 0px 0px'}}>
            {question.answers.map((answer, index) => (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={6}
              >
                <div
                  className={classNames(['img-answer'])}
                  onClick={() => this.checkAnswer(answer)}
                >
                  <img src={answer.imageUrl} style={{maxWidth:'100%', maxHeight:'100%'}}/>
                </div>
              </Col>
            ))}
          </Row>
        </Card>
        <Card className="bg-gradient-info text-center">
          <CardBody>
            <CardTitle className="text-white" tag="h3">

            </CardTitle>
            <blockquote className="blockquote text-white mb-0">
              <p style={{ fontSize: 25, fontWeight: '500' }}>
                {question.text}
              </p>
              <footer className="blockquote-footer text-white">
                Chọn hình phù hợp với từ trên
              </footer>
            </blockquote>
          </CardBody>
        </Card>

      </Container>
    );

  }
}

Type04.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  onNext: PropTypes.func,
  loading: PropTypes.bool,
  question: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
}


export default Type04;
