export const FETCH_REGISTER = 'FETCH_REGISTER';
export const FETCH_REGISTER_REQUEST = 'FETCH_REGISTER_REQUEST';
export const FETCH_REGISTER_SUCCESS = 'FETCH_REGISTER_SUCCESS';
export const FETCH_REGISTER_FAILURE = 'FETCH_REGISTER_FAILURE';

export const FETCH_REGISTER_VIETTEL = 'FETCH_REGISTER_VIETTEL';
export const FETCH_REGISTER_VIETTEL_REQUEST = 'FETCH_REGISTER_VIETTEL_REQUEST';
export const FETCH_REGISTER_VIETTEL_SUCCESS = 'FETCH_REGISTER_VIETTEL_SUCCESS';
export const FETCH_REGISTER_VIETTEL_FAILURE = 'FETCH_REGISTER_VIETTEL_FAILURE';

export const FETCH_ACTIVE_CODE = 'FETCH_ACTIVE_CODE';
export const FETCH_ACTIVE_CODE_REQUEST = 'FETCH_ACTIVE_CODE_REQUEST';
export const FETCH_ACTIVE_CODE_SUCCESS = 'FETCH_ACTIVE_CODE_SUCCESS';
export const FETCH_ACTIVE_CODE_FAILURE = 'FETCH_ACTIVE_CODE_FAILURE';

export const FETCH_CONFRIM_CODE = 'FETCH_CONFRIM_CODE';
export const FETCH_CONFRIM_CODE_REQUEST = 'FETCH_CONFRIM_CODE_REQUEST';
export const FETCH_CONFRIM_CODE_SUCCESS = 'FETCH_CONFRIM_CODE_SUCCESS';
export const FETCH_CONFRIM_CODE_FAILURE = 'FETCH_CONFRIM_CODE_FAILURE';

export const REQUEST_REGISTER_AI = 'REQUEST_REGISTER_AI';
export const REQUEST_REGISTER_AI_SUCCESS = 'REQUEST_REGISTER_AI_SUCCESS';
export const REQUEST_REGISTER_AI_FAILURE = 'REQUEST_REGISTER_AI_FAILURE';

