import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CardFooter } from 'reactstrap';
import  './styles.scss';
import FooterIeltsMindset from 'components/FooterIeltsMindset';
import Checkbox from '@material-ui/core/Checkbox';
import CardBody from 'reactstrap/lib/CardBody';
import * as specifications from '../../../constants/AdjustSpecifications';
import { message } from 'antd';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import * as functions from '../../../../../components/functions';
import CircleTheNumberInTheText from 'modules/IeltsMindsetModule/components/CircleTheNumberInTheText';
import { FETCH_SCORE } from 'modules/IeltsMindsetModule/actions/types';
import NotData from 'components/Error/NotData';
import WrongData from 'components/Error/WrongData';

// const questions = [
//   [{ label: 'Opinion' }, { label: 'Samantha' }, { label: 'Tom' }, { label: 'Sarah' }],
//   [{ label: '1. Joining the gym is too expensive' }, { label: '#', isCorrect: true }, { label: '#', isCorrect: true }, { label: '#' }],
//   [{ label: "2. It's more fun to excercise with other people" }, { label: '#' }, { label: '#' }, { label: '#', isCorrect: true }],
//   [{ label: "3. It's more fun to excercise with other people" }, { label: '#', isCorrect: true }, { label: '#' }, { label: '#', isCorrect: true }],
// ]

const Multiple4 = ({ results, question, audio }) => {
  const [state, setState] = React.useState({
    questions: [],
    // selectedArray: [],
    isPointed: false,
    videoVisible: false,
    isDisabledRetry: true,
    isDisabledSubmit: false,
    answers: [],
  });

  // Cập nhật điểm cho session.
  const dispatch = useDispatch();
  const fetchIeltsMindsetScore = useCallback(
    (studentId, sessionId, assignmentId, takeExamTime) => {
      const payload = { studentId, sessionId, assignmentId, takeExamTime };
      dispatch({ type: FETCH_SCORE, payload });
    },
    [dispatch]
  );
  // #region Gởi dữ liệu tới máy chủ.
  const params = useParams();
  const location = useLocation();
  const { takeExamTime } = queryString.parse(location.search);
  const StudentId = useSelector((state) => state?.loginReducer?.loggedInUser?.userMyames?.StudentId);
  const postAnswer = useCallback(
    (answers, sentences, state) => {
      // Tính điểm.
      const correctAnswers = state.questions.filter((item) => item[0].correctAnswer).length;
      const score = (correctAnswers / sentences.length) * 100;
      // Thuộc tính.
      let result = {
        answerType: 'IELTS', // Đổi từ NEWWORD sang IELTS
        assignmentId: params.assignmentId,
        notes: '',
        questionEntityName: question.questionEntityName,
        groupName: '',
        questionGuid: '',
        questionId: question.id,
        score: score,
        sessionId: params.sessionId,
        studentChoice: JSON.stringify({
          book: question.book,
          unit: question.unit,
          lesson: question.lesson,
          exercise: question.exercise,
          subexercise: question.subExercise ?? '',
          answers: answers,
          score: score,
          questions: { ...state, questions: state.questions },
        }),
        studentId: StudentId,
        takeExamTime: takeExamTime,
        duration: 0,
      };
      // Gởi
      functions
        .postAnswerToAPI(result)
        .then((response) => console.log('Send MC4 answers: success'))
        .catch((error) => console.log('Send MC4 answers', error));
      // Cập nhật điểm.
      fetchIeltsMindsetScore(StudentId, params.sessionId, params.assignmentId, takeExamTime);
    },
    [
      StudentId,
      fetchIeltsMindsetScore,
      params.assignmentId,
      params.sessionId,
      question.book,
      question.exercise,
      question.id,
      question.lesson,
      question.questionEntityName,
      question.subExercise,
      question.unit,
      takeExamTime,
    ]
  );
  // #endregion

  const onSubmit = React.useCallback(() => {
    // #region Kiểm tra làm đủ số câu hỏi chưa?
    const total = state.questions.length;
    let count = 0;
    for (let i = 1; i < total; i++) {
      count += state.questions[i].some((x) => x.checked) ? 1 : 0;
    }

    if (count < total - 1) {
      message.error(specifications.NOTIFICATION_INCOMPLETED_EXERCISE);
      return;
    }
    // #endregion

    const virtualQuestions = []; // Lấy mảng các câu hỏi, không lấy dòng đầu tiên trong mảng

    // Lấy đáp án và câu trả lời.
    state.questions.map((item, questionIndex) => {
      if (questionIndex === 0) return false;
      virtualQuestions.push(item);
      return item.forEach(
        (element, index) =>
          element.label === '#' &&
          element.checked &&
          state.answers.push({
            answer: state.questions[0][index].label,
            isCorrect: element.isCorrect,
            no: questionIndex,
          })
      );
    });
    state.questions.map((item, questionIndex) => {
      if (questionIndex === 0) return false;
      let falseAnswers = 0;
      return item.forEach((element, index) => {
        if (
          element.label === '#' &&
          ((element.checked && !element.isCorrect) || (!element.checked && element.isCorrect))
        ) {
          falseAnswers++;
        }
        if (falseAnswers >= 1) {
          item[0].correctAnswer = false;
        } else {
          item[0].correctAnswer = true;
        }
      });
    });

    // setState((prevState) => ({
    //   ...prevState,
    state.isPointed = true;
    state.isDisabledSubmit = true;
    state.isDisabledRetry = true;
    // }));

    postAnswer(state.answers, virtualQuestions, state);
    setState((prevState) => ({
      ...prevState,
      isPointed: true,
      isDisabledSubmit: true,
      isDisabledRetry: false,
    }));
  }, [postAnswer, state.answers, state.questions]);

  const onRetry = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isPointed: false,
      isDisabledSubmit: false,
      isDisabledRetry: true,
      answers: [],
    }));
  }, []);

  const onChange = React.useCallback(
    (rowIndex) => (e) => {
      const payload = {
        checked: e.target.checked,
        index: e.target.value,
      };
      setState((prevState) => {
        const { questions } = prevState;
        questions[rowIndex][payload.index].checked = payload.checked;
        return { ...prevState, questions };
      });
    },
    []
  );
  const onChangeRadio = React.useCallback(
    (rowIndex, index) => {
      setState((prevState) => {
        const { questions } = prevState;
        questions[rowIndex].map((item, i) => {
          if (i > 0 && i === parseInt(index)) {
            item.checked = true;
          } else if (i > 0 && i !== parseInt(index)) {
            item.checked = false; // Use the assignment operator to update item.checked
          }
          return item; // You should return the updated item
        });
        return { ...prevState, questions };
      });
    },
    []
  );
  const renderCheckBox = React.useCallback(
    (item, index, indexRow) => {
      const { questions } = state;
      let multipleChoice = 0;
      questions.forEach((obj) => {
        questions.forEach((question) => {
          let trueIsCorrectCount = 0;
          question.forEach((item) => {
            if (item.isCorrect === true) {
              trueIsCorrectCount++;
            }
          });
          if (trueIsCorrectCount >= 2) {
            multipleChoice++;
          }
        });

        // If there are two or more true isCorrect values, add the multipleChoice field
      });

      const checked = questions[indexRow][index].checked; // Kiểm tra checked còn lưu trong useState không?
      let color = '#1675BD';
      if (state.isPointed) {
        color = item.checked ? (item.isCorrect ? 'green' : 'red') : '';
      }
      return (
        <>
          {multipleChoice >= 1 ? (
            <Checkbox
              style={{ color }}
              disabled={state.isPointed}
              onChange={onChange(indexRow)}
              value={index}
              checked={checked ? true : false}
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ borderRadius: '50%', border: checked && state.isPointed ? `2px solid ${color}` : '', width: 34, height: 34, }}>
                <input
                  type="radio"
                  id={index}
                  name={indexRow}
                  style={{ color }}
                  disabled={state.isPointed}
                  onChange={() => 
                    onChangeRadio(indexRow, index)}
                  value={index}
                  checked={checked ? true : false}
                />
              </div>
            </div>
          )}
        </>
      );
    },
    [onChange, state]
  );

  const renderItem = React.useCallback(
    (values, indexRow) => {
      return values.map((item, index) => {
        const Component = indexRow === 0 ? 'th' : 'td';
        const isCheckBox = item.label === '#';
        const isStyleCenter = Component === 'th' || isCheckBox;
        const text = indexRow === 0 && item.label === ' ' ? 'Statements' : item.label;
        const color = results
          ? state.isPointed
            ? item.correctAnswer
              ? 'green'
              : item.correctAnswer === false
              ? 'red'
              : specifications.QUESTION_COLOR
            : specifications.QUESTION_COLOR
          : specifications.QUESTION_COLOR;
        const itemContent = isCheckBox ? (
          renderCheckBox(item, index, indexRow)
        ) : (
          <CircleTheNumberInTheText text={text} />
        );
        return (
          <Component
            key={`Component-${index}`}
            style={{
              textAlign: isStyleCenter ? 'center' : '',
              color: color,
              fontSize: specifications.QUESTION_FONT_SIZE,
              fontWeight: indexRow === 0 ? 'bold' : specifications.QUESTION_FONT_WEIGHT,
              margin: specifications.QUESTION_SPACE_BETWEEN_SENTENCES,
              padding: indexRow === 0 && 0,
              position: indexRow === 0 && 'sticky', // Fix header
              top: indexRow === 0 && 0,
              backgroundColor: indexRow === 0 && 'white',
              borderTop: indexRow === 0 && 'none',
              zIndex: indexRow === 0 && 1,
            }}
          >
            {indexRow === 0 ? (
              <div style={{ padding: 8, borderTop: '2px solid #1675BD' }}>{itemContent}</div> // Xử lý border trống khi header fixed
            ) : (
              itemContent
            )}
          </Component>
        );
      });
    },
    [renderCheckBox]
  );

  const renderContent = React.useCallback(() => {
    return state.questions.map((item, index) => {
      return <tr key={`tr-${index}`}>{renderItem(item, index)}</tr>;
    });
  }, [renderItem, state.questions]);

  React.useEffect(() => {
    if (results) {
      if (results.studentChoice.questions) {
        setState(results.studentChoice.questions);
      } else {
        const resultsArr = JSON.parse(question.questionJson).questionsArray;

        resultsArr.map((item, index) => {
          if (
            index > 0 &&
            (results.studentChoice?.answers[index - 1]?.answer === 'True' ||
              results.studentChoice?.answers[index - 1]?.answer === 'Yes')
          ) {
            item[1].checked = true;
          } else if (
            index > 0 &&
            (results.studentChoice?.answers[index - 1]?.answer === 'False' ||
              results.studentChoice?.answers[index - 1]?.answer === 'No')
          ) {
            item[2].checked = true;
          }
        });
        setState({
          isDisabledRetry: true,
          isDisabledSubmit: true,
          isPointed: true,
          answers: results.studentChoice.answers,
          questions: resultsArr,
        });
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        questions: JSON.parse(question.questionJson).questionsArray,
      }));
      return () => {
        setState((prevState) => ({
          ...prevState,
          questions: [],
          // selectedArray: [],
          isPointed: false,
          videoVisible: false,
          isDisabledRetry: true,
          isDisabledSubmit: false,
          answers: [],
        }));
      };
    }
  }, [question.questionJson]);
  if (typeof question === 'undefined') return <NotData />;
  if (!state.questions) return <WrongData />;

  return (
    <div id='MC4'>
      {/* <Row className='d-flex justify-content-center'>
        <Col className='d-initial justify-content-center'> */}
      <CardBody style={{ overflowY: 'auto', padding: 0 }}>
        <table >
          <tbody>{renderContent()}</tbody>
        </table>
      </CardBody>
      <CardFooter style={{ padding: 0 }}>
        <FooterIeltsMindset
          question={question}
          results={results}
          isDisabledSubmit={state.isDisabledSubmit}
          isDisabledRetry={state.isDisabledRetry}
          onSubmit={onSubmit}
          onRetry={onRetry}
          audioUrl={audio}
        />
      </CardFooter>
      {/* </Col>
      </Row> */}
    </div>
  );
};

Multiple4.propTypes = {
  question: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  sessionId: PropTypes.string,
  classId: PropTypes.string,
  audio: PropTypes.string,
};

export default Multiple4;
