import { Rate } from 'antd';
import Listen from 'components/Listening';
import * as functions from 'components/functions';
import RdIcon from 'components/functions/rdIcons';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

const arrayAnswer = ['A', 'B', 'C', 'D'];

const Type31Modal = ({ detail, type, index }) => {
  if (!detail) return null;
  const studentChoices = JSON.parse(detail.studentChoices);

  //Nếu questions - loại chọn nhiều câu hỏi nhỏ thì render kiểu khác
  const modeRender = studentChoices.question.questions ? 'MULTIPLE' : 'SINGLE';

  const renderIcons = () => {
    const iconArray = RdIcon(type);
    return iconArray?.map((item, index) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a key={index} className="avatar avatar-sm rounded-circle" onClick={(e) => e.preventDefault()}>
          <img alt="..." src={item} />
        </a>
      );
    });
  };
  const renderMultipleAnswerItem = (question) => {
    const studentAnswer = question?.selectedItem?.answer ?? '';
    const answerCorrect = question.answers?.find((i) => i.isCorrect)?.answer ?? '';
    return (
      <div id={question.id ?? '123'}>
        <ListGroupItem>
          {/* <p className='mt-2' style={{ fontSize: 18, fontWeight: '500' }}>Từ phát âm: {studentChoices.vocabularyQuestion[0].text}</p> */}

          <p className="mt-3 text-primary" style={{ fontSize: 16, fontWeight: '500', textAlign: 'left' }}>
            Questions {question.answerIndex ? question.answerIndex : ''}: {question.questionText}
          </p>
          <p className="text-success" style={{ fontSize: 16, fontWeight: '500', textAlign: 'left' }}>
            Your Answer: {studentAnswer}
          </p>
          <p className="text-red" style={{ fontSize: 16, fontWeight: '500', textAlign: 'left' }}>
            Correct Answer:{answerCorrect}
          </p>
        </ListGroupItem>
      </div>
    );
  };

  const renderAnswers = () => {
    if (modeRender === 'MULTIPLE') {
      return studentChoices.question?.questions?.map((question) => renderMultipleAnswerItem(question));
    } else {
      const selectedItem = studentChoices.question.selectedItem;
      const answersArray = JSON.parse(studentChoices.question.answers);
      const indexCorrect = answersArray?.findIndex((x) => x.isCorrect === true);
      const answerCorrect = `${arrayAnswer[indexCorrect]}${
        answersArray[indexCorrect]?.answer ? '. ' + answersArray[indexCorrect]?.answer : ''
      }`;
      const studentAnswer = `${arrayAnswer[selectedItem.index]}${
        selectedItem?.answer ? '. ' + selectedItem?.answer : ''
      }`;

      return (
        <ListGroupItem>
          {/* <p className='mt-2' style={{ fontSize: 18, fontWeight: '500' }}>Từ phát âm: {studentChoices.vocabularyQuestion[0].text}</p> */}
          <p className="mb-3 text-primary" style={{ fontSize: 16, fontWeight: '500' }}>
            Question {index + 1}. {studentChoices.question.questionText}
          </p>
          {studentChoices.question.imageUrl !== '' && (
            <img
              style={{  maxWidth: 400, maxHeight: 250, objectFit: 'cover' }}
              alt="..."
              src={studentChoices.question.imageUrl}
            />
          )}

          {answersArray?.[0].answer !== '' && (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {JSON.parse(studentChoices.question.answers).map((item, index) => (
                <p className="mb-3 text-primary" style={{ fontSize: 16, fontWeight: '500' }} key={item.answer}>
                  {arrayAnswer[index]}. {item.answer}
                </p>
              ))}
            </div>
          )}
          {studentChoices.question.audioUrl !== '' && (
            <p className="mt-3 text-primary" style={{ fontSize: 16, fontWeight: '500' }}>
              Audio:
              <Listen
                custom
                audioURL={studentChoices.question.audioUrl}
                className={'question-type__audioExampleType02 ml-2'}
              >
                <i className="fas fa-volume-up"></i>
              </Listen>
            </p>
          )}

          <p className="text-success" style={{ fontSize: 16, fontWeight: '500' }}>
            Your Answer: {studentAnswer}
          </p>
          <p className="text-red" style={{ fontSize: 16, fontWeight: '500' }}>
            Correct Answer: {answerCorrect}
          </p>
        </ListGroupItem>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="modal-body text-center">
        <ListGroup>
          <ListGroupItem>
            <p>{renderIcons()}</p>
            <p className="text-red" style={{ fontSize: 18, fontWeight: '500' }}>
              Your Rate:
            </p>
            <Rate disabled value={functions.getStarRecord(detail.score)} allowHalf />
          </ListGroupItem>
          {renderAnswers()}
        </ListGroup>
      </div>
    </React.Fragment>
  );
};

Type31Modal.propTypes = {
  detail: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  index: PropTypes.number || PropTypes.string,
};
export default Type31Modal;
