
import { Rate } from 'antd';
import Listen from 'components/Listening';
import RdIcon from 'components/functions/rdIcons';
import queryString from 'query-string';
import React from 'react';
import NotData from 'components/Error/NotData';
import Loading from 'components/Loading';
import * as functions from 'components/functions';
import PropTypes from 'prop-types';
import { queryFirst } from 'helpers/QueryHelper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { query } from 'helpers/QueryHelper';
class Type08Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      resultDetail: null,
    };
    this.queryString = queryString.parse(window.location.search);
    this.pathnameParams = this.props.match.params;
  }

  componentDidMount = () => {
    this.getResult();
  };

  getResult = async () => {
    //'/class/:classId/session/:sessionId/assignment/:assignmentId/results'
    const {  match,  loggedInUser } = this.props;
    const { assignmentId, sessionId, classId } = this.pathnameParams;

    const res = await query('p_AMES247_Student_Stories_GetResultOfPlayedTime_Detail_Results', {
      StudentId:  functions.getStudentId()?.MyAmesStudentId,
      TakeExamTime: this.queryString.takeExamTime,
      questionId: assignmentId,
      CurrentIndexQuestion: this.props.detail.stt,
    });
    if (res) {
      this.setState({ resultDetail: res });
    }
      this.setState({ loading: false });
  };

  renderIcons = () => {
    const iconArray = RdIcon(this.props.type);
    return iconArray?.map((item, index) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a key={index} className="avatar avatar-sm rounded-circle" onClick={(e) => e.preventDefault()}>
          <img alt="..." src={item} />
        </a>
      );
    });
  };
  render = () => {
    const { loading,
      resultDetail} = this.state
      const { questionText, studentRecordUrl, score ,bookLevel,
folderName} = resultDetail?.[0] ?? {};
      const index = this.props.detail.stt
    const baseUri = `https://toeic.ames.edu.vn/Data/Reading/${bookLevel}/${folderName}`;
    const imageBaseUri = `${baseUri}/images/${index}.jpg`;
    const soundBaseUri = `${baseUri}/sounds/${index}.mp3`;
    if (loading) {
      return <Loading />;
    }
    if (!resultDetail || resultDetail?.length === 0) {
      return <NotData />;
    }

    return (
      <div className="modal-body text-center">
        <p>{this.renderIcons()}</p>
        <p className="text-red" style={{ fontSize: 18, fontWeight: '500' }}>
          Your Score: {score}
        </p>
        <Rate disabled value={functions.getStarRecord(score)} allowHalf />
        <br />
        <img alt="hinh_anh" src={imageBaseUri} style={{ maxHeight: 200, maxWidth: 300 }} />

        <p className="mt-3 text-primary" style={{ fontSize: 16, fontWeight: '500' }}>
          {questionText}{' '}
          <Listen custom audioURL={soundBaseUri} className={'question-type__audioExampleType02 ml-2'}>
            <i className="fas fa-volume-up"></i>
          </Listen>
        </p>

        <hr />

        <p className="text-blue" style={{ fontSize: 16, fontWeight: '500' }}>
          Your Answer:
          <Listen custom audioURL={studentRecordUrl} className={'question-type__audioExampleType02 ml-2'}>
            <i className="fas fa-volume-up"></i>
          </Listen>
        </p>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUser,
});

Type08Modal.propTypes = {
  type: PropTypes.string,
  detail: PropTypes.any.isRequired,
  loggedInUser: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired, // lấy từ redux
  location: PropTypes.instanceOf(Object).isRequired, // lấy từ redux
};

export default connect(mapStateToProps)(withRouter(Type08Modal));
