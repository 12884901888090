import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardBody, CardFooter, CardHeader } from 'reactstrap';
import { Button, Col, Empty, Pagination, Row, Space, Spin } from 'antd';
import colors from 'constants/colors';
import { useQuery } from 'react-query';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import CardItem from './components/CardItem/CardItem';
import './style.scss';

function AcademicReport() {
  const loggedInUser = useSelector((state) => state.teacherReducer.loggedInUser ?? state.loginReducer.loggedInUser);
  const studentId = loggedInUser.userMyames?.StudentId ?? loggedInUser.userMyai?.StudentId ?? 0;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(pageNumber * 10);

  const { data, isLoading } = useQuery(
    ['GET_ACADEMIC_REPORT_DATA', pageNumber],
    () =>
      Axios.get(
        `https://cloud.softech.vn/mobile/MyAmes/api/getnewsfeed-learning?page=${pageNumber-1}&studentId=${studentId}` //page trên server tính từ page 0
      ),
    { enabled: !!pageNumber && !!studentId }
  );

  const lists = data?.data?.results?.items;
  return (
    <div className="academic_report_container">
      <CardHeader style={{ background: '#022F63', color: 'white' }}>
        <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 20 }}>BÁO CÁO HỌC TẬP</div>
      </CardHeader>
      <div>
        {isLoading ? (
          <Row justify="center" className='m-4'>
            <Spin spinning={true} tip="Đang tải dữ liệu ..." size="large" />
          </Row>
        ) : lists && lists?.length > 0 ? (
          <div className="body_content">
            {lists.map((item, index) => {
              return <CardItem key={index} data={item} />;
            })}
          </div>
        ) : (
          <div className='m-4'>
            <Empty description="Không có dữ liệu"></Empty>
          </div>
        )}
      </div>
      <Row justify="end" style={{ marginTop: 10 }}>
        <Space>
          <Pagination
            current={pageNumber}
            total={totalRows}
            onChange={(page, pageSize) => {
              setPageNumber(page);
            }}
          />
          <Button
            type="primary"
            style={{ backgroundColor: colors.theme.default }}
            onClick={() => {
              // Có dữ liệu thì mới cho next tiếp
              if (lists && lists?.length > 0) {
                setPageNumber((pre) => pre + 1);
                if (pageNumber * 10 >= totalRows) {
                  setTotalRows((pageNumber + 1) * 10);
                }
              }
            }}
          >
            Next
          </Button>
        </Space>
      </Row>
    </div>
  );
}

AcademicReport.propTypes = {};

export default AcademicReport;
