export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE';

export const POST_LOGOUT = 'POST_LOGOUT';

export const POST_RESET_PASS = 'POST_RESET_PASS';
export const POST_RESET_PASS_REQUEST = 'POST_RESET_PASS_REQUEST';
export const POST_RESET_PASS_SUCCESS = 'POST_RESET_PASS_SUCCESS';
export const POST_RESET_PASS_FAILURE = 'POST_RESET_PASS_FAILURE';


export const RESET_PASS_CHECK_CODE_REQUEST = 'POST_RESET_PASS_REQUEST';
export const RESET_PASS_CHECK_CODE_SUCCESS = 'POST_RESET_PASS_SUCCESS';
export const RESET_PASS_CHECK_CODE_FAILURE = 'POST_RESET_PASS_FAILURE';

export const POST_CHECK_VOUCHER = 'POST_CHECK_VOUCHER';
export const POST_CHECK_VOUCHER_REQUEST = 'POST_CHECK_VOUCHER_REQUEST';
export const POST_CHECK_VOUCHER_SUCCESS = 'V_SUCCESS';
export const POST_CHECK_VOUCHER_FAILURE = 'POST_CHECK_VOUCHER_FAILURE';
