import * as React from 'react';
import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash.flow';
import Listen from 'components/Listening';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ComponentProps = {
  type: PropTypes.oneOf(['text', 'image', 'sound']).isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
  moveCard: PropTypes.func
};

class CardUI extends React.Component {
  renderChild = () => {
    const { type, data } = this.props;
    switch (type) {
      case 'text':
        return <span style={{ display: '-webkit-box', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical'}}>{data.text}</span>;
      case 'image':
        return <img alt="..." src={data.imageUrl} style={{width: '100%', aspectRatio: '190/140'}} />;
      case 'sound':
        return (
          <Listen audioURL={data.soundUrl}>
            <i style={{ fontSize: 15 }} className="fas fa-volume-up"></i>
          </Listen>
        );
      default:
        return null;
    }
  };

  render() {
    const { type, result } = this.props;
    return (
      <Button style={{ minHeight: 'min(100px,15vw)', width: 'min(100px,15vw)', fontWeight: 'bold', fontSize: 'min(15px,2vw)', padding: '0.3rem 0.6rem' }} color={result === 1 ? 'success' : result === 0 ? 'danger' : type === 'text' ? 'info' : undefined}>
          {this.renderChild()}
        </Button>
    );
  }
}

CardUI.propTypes = ComponentProps;



export { CardUI };
