import { axiosAMES } from 'configs/api';
import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/types';
import { query } from 'helpers/QueryHelper';
import { queryFirst } from 'helpers/QueryHelper';

function* getResult(action) {
  const { studentId, sessionId, assignmentId, takeExamTime, classId } = action.payload;
  try {
    if (classId === 0) {
      const params = {
        studentId,
        takeExamTime,
        questionId: assignmentId,
      };
      const response = yield queryFirst('p_AMES247_Student_Stories_GetResultOfPlayedTime', params);
      const response2 = yield query('p_AMES247_Student_Stories_GetResultOfPlayedTime_Details', params);
      const results = [
        {
          ...response,
          playedTime: response?.timeSpan ?? 0,
          passedQuestionCount: response?.correct ?? 0,
          totalAnswer: response?.answered ?? 0,
          totalQuestionCount: response?.totalQuestions ?? 0,
          resultLogs: response2 ? JSON.stringify(response2) : null,
        },
      ];
      yield put({
        type: actionTypes.GET_RESULT_SUCCESS,
        payload: results,
      });
    } else {
      //Thêm  "_v2"
      //thêm classId
      const body = new FormData();
      body.append('studentId', studentId);
      body.append('sessionId', sessionId);
      body.append('assignmentId', assignmentId);
      body.append('takeExamTime', takeExamTime);
      body.append('classId', isNaN(classId) ? 0 : classId); // trường hợp classId khác số thì để bằng 0
      const response = yield axiosAMES.post('/GetResultOfPlayedTime_v2', body);
      yield put({
        type: actionTypes.GET_RESULT_SUCCESS,
        payload: response.data.results,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_RESULT_FAILURE,
      payload: { error },
    });
  }
}

export default function* resultSaga() {
  yield takeLatest(actionTypes.GET_RESULT_REQUEST, getResult);
}
