import Axios from 'axios';
import { cdnServerUrl } from 'constants/serverUrls';
import { sachsoServerUrl } from 'constants/serverUrls';

const authorizationKey = 'Basic 12C1F7EF9AC8E288FBC2177B7F54D';
const MODULE_NAME = 'IELTS_WORKBOOK_AMES';
const APPLICATION_NAME = 'IELTS';

function UploadFile({ file,fileName , isGetOnlyUrl }) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append('files', file,fileName);
    formData.append('moduleName', 'MYAMES-WEB');
    formData.append('subModuleName', '');
    formData.append('entityName', 'FILES');
    formData.append('entityId', '00000000-0000-0000-0000-000000000000');
    formData.append('sqlCommand', 'p_SHARE_Attachments_Add');

    const response = await Axios.post(`${sachsoServerUrl}/api/v1.0/dynamic/upload-files`, formData, {
      headers: {
        Authorization: authorizationKey,
        ApplicationName: APPLICATION_NAME,
      },
      //   cancelToken: source.token,
    });

    if (response.data.files?.[0]?.downloadUrl) {
      if (isGetOnlyUrl) {
        resolve(response.data.files?.[0]?.downloadUrl);
      } else {
        resolve(response.data.files?.[0]);
      }
    } else {
      reject(response);
    }
  });
}

function DeleteFileByUrl(url) {
  try {
    if (url) {
      const filePath = url.replace(`${cdnServerUrl}/storage/`, '');
      const bodyFormData = new FormData();
      bodyFormData.append('filePath', filePath);
      return Axios({
        url: `${sachsoServerUrl}/api/v1.0/dynamic/del-file`,
        method: 'POST',
        headers: {
          Authorization: authorizationKey,
          ApplicationName: APPLICATION_NAME,
          'Content-Type': 'multipart/form-data',
        },
        data: bodyFormData,
      });
    } else return Promise.reject('Thiếu filePath');
  } catch (error) {
    return Promise.reject('Có lỗi xảy ra');
  }
}

export {UploadFile, DeleteFileByUrl };
