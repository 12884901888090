import { FullscreenOutlined } from '@ant-design/icons';
import { Image, Tooltip } from 'antd';
import React from 'react';
import './QuestionImage.scss';

const QuestionImage = ({ src, alt = '...' }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <div id="QuestionImage" key={src}>
      <div className="screen_img">
        <Image alt={alt} src={src} />
      </div>
    </div>
  );
  //Cách dưới là không hiển thị mặc định preview như antd
  return (
    
    <div id="QuestionImage" key={src}>
      <div className="header">
        <Tooltip title="Full screen">
          <span className="button" onClick={() => setVisible(true)}>
            <FullscreenOutlined style={{ fontSize: 40 }} />
          </span>
        </Tooltip>
      </div>
      <div className='screen_img'>
        <img src={src} alt={alt} />
      </div>
      <Image
        preview={{ visible: visible, onVisibleChange: (vis) => setVisible(vis) }}
        alt={alt}
        src={src}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default QuestionImage
