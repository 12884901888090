import { BackTop, Space } from 'antd';
import NotData from 'components/Error/NotData';
import Loading from 'components/Loading';
import TitleQuestion from 'components/TitleQuestion';
import * as Colors from 'configs/color';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, Col, Container, Row, Button } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
import QuestionType from '../../../modules/IeltsMindsetModule/ExcerciseTypes';
import styles from './styles.module.css';
// import Sidebar from './components/Sidebar';
import { query } from 'helpers/QueryHelper';
import CardBody from 'reactstrap/lib/CardBody';
import AudioList from '../../../modules/IeltsMindsetModule/components/AudioList';
import { QUESTION_BACKGROUND_COLOR } from '../../../modules/IeltsMindsetModule/constants/AdjustSpecifications';

function Result({ resultsData }) {
  const [questionIndex, setQuestionIndex] = React.useState(0);

  const results = resultsData[questionIndex].studentChoice ? resultsData[questionIndex] : null;
  const removeAccents = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D');
  };
  const question = resultsData?.[questionIndex]?.question[0];
  const imgArray = JSON.parse(question.questionImage);

  const fullName = resultsData.find((item) => item.fullName !== null).fullName;
  const className = resultsData.find((item) => item.className !== null).className;
  const transform = React.useCallback(
    (node, i) => {
      let count = 0;
      if (node.type === 'text') {
        if (!node.data.includes('#')) return;
        const elementArray = node.data.split('#');
        return (
          <span key={i}>
            {elementArray.map((item, index) => {
              if (index < elementArray.length - 1) {
                count++;
              }
              return (
                <React.Fragment key={index}>
                  {item}
                  {imgArray && index < elementArray.length - 1 && (
                    <img
                      src={imgArray[count - 1]}
                      style={{
                        height: 'fit-content',
                        // width: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      alt="img"
                    />
                  )}
                </React.Fragment>
              );
            })}
          </span>
        );
      }
    },
    [imgArray]
  );

  const renderQuestion = React.useCallback(() => {
    return (
      <React.Fragment>
        <CardBody style={{ padding: 0 }}>
          {question?.questionText && ReactHtmlParser(question.questionText, { transform })}
        </CardBody>
        {/* <Component question={question} audio={question?.audio} /> */}
      </React.Fragment>
    );
  }, [results]);

  const Component = QuestionType[resultsData[questionIndex]?.type];
  if (!Component) return <NotData />;

  const color = `header bg-${Colors.PRIMARY} pb-6`;

  return (
    <>
      <div className={color}>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center">
              <Col xl={11}>
                <Link to="/ames">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {removeAccents(fullName).toUpperCase()}'S EXCERCISE DETAILS
                  </h6>
                </Link>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem className="active">{className}</BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    Sessions
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    Unit {question?.unit.split('-')[0].trim()}: {question?.unit.split('-')[1]} | Lesson{' '}
                    {question?.lesson.split('-')[2]} | Assignments
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <span style={{ fontSize: 15, color: 'white' }}>Exercise {question?.exercise}</span>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Exercise section */}
      <Container className="mt--5" fluid style={{ height: 'calc(100vh - 131px)' }}>
        <Card style={{ flex: 1, height: 'inherit', margin: 0 }}>
          <CardHeader style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}>
            <TitleQuestion
              no={question?.exercise}
              type={`${question?.unit}: Lesson ${question?.lesson}`}
              title={question?.title}
              subtitle={question?.subtitle}
              subExercise={question?.subExercise}
            />
            <AudioList audioUrl={question?.audio} />
          </CardHeader>
          <Row style={{ height: 'inherit', margin: 0, padding: 15, overflow: 'hidden' }}>
            {question?.questionText ? (
              <React.Fragment>
                <Col
                  style={{
                    padding: '0 15px 0 0',
                    overflowY: 'auto',
                    height: '100%',
                    textAlign: 'justify',
                    backgroundColor: QUESTION_BACKGROUND_COLOR,
                  }}
                >
                  {renderQuestion()}
                </Col>
                <Col
                  className="d-flex flex-column"
                  style={{ padding: '0 0 0 15px', height: '100%', overflowY: 'auto' }}
                >
                  <Component
                    results={results}
                    question={question}
                    audio={question?.audio}
                    tapescript={question?.listenTranscript}
                  />
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col className="d-flex flex-column" style={{ padding: 0, height: '100%' }}>
                  <Component
                    results={results}
                    question={question}
                    audio={question?.audio}
                    tapescript={question?.listenTranscript}
                  />
                </Col>
              </React.Fragment>
            )}
          </Row>
          <Space className={`${styles.wrapColLeft}`}>
            <Button
              disabled={questionIndex === 0}
              id="Back"
              color="default"
              type="button"
              className="btn-icon btn-2"
              onClick={() => setQuestionIndex((f) => f - 1)}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-arrow-circle-left"></i>
              </span>
            </Button>
            <Button
              disabled={questionIndex === resultsData?.length - 1}
              id="Next"
              color="default"
              type="button"
              className="btn-icon btn-2"
              onClick={() => setQuestionIndex((f) => f + 1)}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-arrow-circle-right"></i>
              </span>
            </Button>
            {!resultsData[questionIndex].studentChoice && (
              <strong style={{ color: '#ff5e00' }}>
                {`${removeAccents(fullName)} has not done this exercise yet!`.toUpperCase()}
              </strong>
            )}
          </Space>
        </Card>
      </Container>
      <BackTop />
    </>
  );
}

export default Result;
