import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Space } from 'antd';

//
export const footerKey = {
  EDIT: 'Edit',
  PREVIEW: 'Preview',
  VIEW: 'View',
  CREATE: 'Create',
};
function Footer({refSubmit, footerMode, setFooterMode }) {
  const [isDoing, setIsDoing] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);

  const { EDIT, PREVIEW, VIEW, CREATE } = footerKey;

  //Chấm điểm bài WS
  const handleSubmit = () => {
    refSubmit.current.submit();
    setIsDoing(false);
  };
  //làm lại thử bài WS
  const handletryAgain = () => {
    refSubmit.current.tryAgain();
    setIsDoing(true);
  };

  //Lưu chỉnh sửa canvas cho dữ liệu làm bài WS
  const handleSaveCanvas = async () => {
    await refSubmit.current?.submit?.();
    setIsSubmited(true);
  };
  
  //Lưu chỉnh sửa canvas cho dữ liệu làm bài WS
  const handlePreview = async () => {
    setIsSubmited(false);
    setFooterMode(PREVIEW);
  };

  const renderButton = () => {
    switch (footerMode) {
      case CREATE: 
      case EDIT: {
        return (
          isSubmited ?
          (<Button
            type="primary"
            onClick={handlePreview}
          >
            Xem trước
          </Button>) : 
          (
            <Button
            type="primary"
            onClick={handleSaveCanvas}
          >
            Lưu
          </Button>
        )
        );
        // break;
      }
      case PREVIEW: {
        return (
          <Space>
            <Button type="primary" onClick={() => setFooterMode(EDIT)}>
              Chỉnh sửa
            </Button>

            {isDoing === false ? (
              <Button type="primary" onClick={handletryAgain}>
                Làm lại
              </Button>
            ) : (
              <Button type="primary" onClick={handleSubmit}>
                Kiểm tra
              </Button>
            )}
          </Space>
        );
        // break;
      }
      case VIEW: {
        return (
          <Space>
            {/* <Button Button type='primary' onClick={() => setFooterMode(EDIT)}>Chỉnh sửa</Button> */}

            {isDoing === false ? (
              <Button type="primary" onClick={handletryAgain}>
                Làm lại
              </Button>
            ) : (
              <Button type="primary" onClick={handleSubmit}>
                Kiểm tra
              </Button>
            )}
          </Space>
        );
        // break;
      }
      default: {
        return (
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        );
        // break;
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <div />
        </Col>
        <Col span={8} className="d-flex justify-content-center align-items-center">
          <div id="Footer-ExcercisePage-Audio" />
        </Col>
        <Col span={8}>
          <div>{renderButton()}</div>
        </Col>
      </Row>
    </>
  );
}

Footer.propTypes = {
  refSubmit: PropTypes.any,
  footerMode: PropTypes.string,
  setFooterMode: PropTypes.func
};

export default Footer;
