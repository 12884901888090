import openNotificationWithIcon from 'components/Notification';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { DragCard, CardUI } from './DragCard';
import * as textQuestionTypes from '../typesQuestion';

const update = require('immutability-helper');

class MatchingWordSoundPicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: [],
      arrow: [],
      next: false,
      rightArray: [],
      leftArray: [],
      exerciseCountdowns: [],
    };

    this.score = 0;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.question !== state.question) {
      const quesIndex = props.questionIndex;
      let answers = props.data?.slice(quesIndex * 4, (quesIndex + 1) * 4);
      
      // If num of answers less than 4 then choose randomly the rest answers to fill up 4 slot
      if (0 < answers?.length < 4 ) {
        const randomAnswers = _.sampleSize(props.data?.filter((item) => !answers.some((answersItem) => item.id === answersItem.id )), (4 - answers?.length))
        console.log(randomAnswers)
        answers = answers.concat(randomAnswers)
      }

      let arrow = [];
      if (!answers) return;
      answers.forEach(() => {
        arrow.push({ color: 'Yellow' });
      });

      const rightArray = _.shuffle(answers);
      return {
        question: props.question,
        leftArray: answers,
        rightArray,
        arrow,
      };
    }
    return null;
  }

  moveCard = (dragIndex, hoverIndex) => {
    if (this.state.next === false) {
      const { rightArray } = this.state;
      const dragCard = rightArray[dragIndex];

      this.setState(
        update(this.state, {
          rightArray: {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          },
        })
      );
    }
  };

  onNext = () => {
    const { rightArray, leftArray, next, exerciseCountdowns } = this.state;
    const { onNext } = this.props;
    if (!next) {
      this.checkAnswer();
      return;
    }
    const isPush = false;
    const postAnswerToApiParams = {
      notes: '',
      questionGuid: '',
      answerType: 'MATCHING',
      studentChoice: JSON.stringify({
        score: parseInt(this.score),
        pairs: {
          left: leftArray,
          right: rightArray,
        },
      }),
    };
    onNext(exerciseCountdowns, postAnswerToApiParams, isPush);
    this.setState({ next: false });
    this.score = 0;
  };

  checkAnswer = () => {
    const { questionIndex } = this.props;
    const { rightArray, leftArray, arrow, exerciseCountdowns } = this.state;
    const resultAnswer = { cards: rightArray, arrayText: leftArray };
    let passed = 0; // count
    for (let i = 0; i < leftArray.length; i++) {
      if (rightArray[i].id === leftArray[i].id) {
        passed++;
        arrow[i].color = 'Green';
        this.score += parseInt(100 / leftArray.length);
      } else {
        arrow[i].color = 'Red';
      }
    }
    let isCorrect = '';

    if (this.score > 50) {
      isCorrect = true;
      openNotificationWithIcon('success', 'CORRECT');
    } else {
      openNotificationWithIcon('danger', 'INCORRECT');
    }

    exerciseCountdowns.push({ resultAnswer, questionIndex, isDone: isCorrect, score: this.score });

    this.setState({ next: true });
  };

  arrowRender = (index) => {
    const { arrow } = this.state;
    const item = arrow[index]
    const imageUrl = require(`assets/img/Arrow${item.color}.png`);
      return (
          <img style={{ width: 'min(80px,10vw)', aspectRatio : '1/1' }} src={imageUrl} alt="..." />
      );
  };
  //type = 'text'| 'image'| 'sound'
  renderCard = (index,type, isRight) => {
    const { rightArray, leftArray } = this.state;
    if (isRight) {
    const item = rightArray[index];
    return (<DragCard key={index + type} type={type} data={item} index={index} moveCard={this.moveCard} />);
    }
    const item = leftArray[index];
    return (<CardUI key={index + type} type={type} data={item} isRight={false} />);
  };

  renderRow = (index) => {
    const { questionType } = this.props;
    switch (questionType) {
      case textQuestionTypes.Type15: {
        return (
          <>
            <Col>{this.renderCard(index,'text')}</Col>
            <Col className="text-white">{this.arrowRender(index)}</Col>
            <Col>{this.renderCard(index,'image', true)}</Col>
          </>
        );
      }
      case textQuestionTypes.Type16: {
        return (
          <>
            <Col>{this.renderCard(index,'sound')}</Col>
            <Col className="text-white">{this.arrowRender(index)}</Col>
            <Col>{this.renderCard(index,'text', true)}</Col>
          </>
        );
      }
      case textQuestionTypes.Type17:
      case textQuestionTypes.Type17A: {
        return (
          <>
            <Col>{this.renderCard(index,'sound')}</Col>
            <Col className="text-white">{this.arrowRender(index)}</Col>
            <Col>{this.renderCard(index,'image', true)}</Col>
          </>
        );
      }
      default:
        return null;
    }
  };

  renderQuestion = () => {
    const { leftArray } = this.state;
    return leftArray.map((item, index) => {
      return (
        <Row key={index} className="bg-gradient-secondary justify-content-md-center text-center align-items-center mb-4">
          {this.renderRow(index)}
        </Row>
      );
    });
  };

  render() {
    const { next } = this.state;

    return (
      <Container>
        {this.renderQuestion()}
        <Row className="text-center">
          <Col className="mt-2">
            <Button color="primary" size="lg" onClick={() => this.onNext()}>
              {next === false ? 'Kiểm tra' : 'Tiếp tục'}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

MatchingWordSoundPicture.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  question: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default MatchingWordSoundPicture;
