export const Type01 = 'Listening';
export const Type02 = 'ListenAndRepeat'; //ok
export const Type03 = 'OneCorrectQuestionText'; //ok
export const Type04 = 'OneCorrectQuestionImage'; //ok
export const Type05 = 'OneTextMultiOptionOneCorrect'; //ok
export const Type06 = 'SpeakCorrectEnglishFromVietnamese';
export const Type07 = 'RepeatTheWords'; //ok
export const Type08 = 'LookWordAndImageThenListenAndRepeat';
export const READING_BOOK = 'READING_BOOK';
export const Type09 = 'SayTheWordsText';
export const Type10 = 'SayTheWordsImage';
export const Type11 = 'LookSentenceAndImageThenListenAndRepeat';
export const Type12 = 'RepeatTheSentence'; //ok
export const Type12_A = 'RepeatTheSentence_A';
export const Type13 = 'ScrambleWord'; //ok
export const Type14 = 'MakeASentence'; //ok
export const Type15 = 'MatchingWordWithPicture'; //ok
export const Type16 = 'MatchingWordWithSound'; //ok
export const Type17 = 'MatchingSoundWithPicture'; //ok
export const Type17A = 'MatchingSoundWithPicture_A'; //ok
export const Type18 = 'CompleteWord'; //ok
export const Type19 = 'ListenAndFillToTheBlank';
export const Type20 = 'Grammar'; //ok
export const Type21 = 'ScrambleWordForSS';
export const Type22 = 'CompleteWordForSS';
export const Type23 = 'SpeakOut_Conversations';
export const Type24 = 'SpeakOut_Vocabularies';
export const Type25 = 'SpeakOut_Structures';
export const Type26 = 'OneCorrectQuestionImage_A';
export const Type27 = 'ReadingLexileOnePerson';
// export const Type28 = 'MatchingSoundWithPicture_A';
export const Type29 = 'ScrambleWordForSS_A'; //ok
export const Type30 = 'CompleteWordForSS_A'; //ok
export const Type31_OLD = 'ReadingLexileTwoPerson';
export const Type31 = 'TOEIC_LISTENING_READING'; //ok
export const Type32 = 'IMAGE_TEXT_RECORD'; //ok
// export const Type32 = 'ConversationTwoPerson';
export const Type33 = 'ConversationOnePerson';
export const Type34 = 'ListenAudio';
// export const Type35 = 'IMAGE_TEXT_RECORD'
export const Type36 = 'IELTS_EXPLORER';
export const Type37 = 'IELTS_DICTATION';
// export const Type38 = 'MINDSET_FOR_IELTS-MULTIPLE_CHOICE'
// export const Type39 = 'MINDSET_FOR_IELTS-SELECT_TYPE'
// export const Type40 = 'MINDSET_FOR_IELTS-TYPE_IN'
// export const Type41 = 'MINDSET_FOR_IELTS-MATCHING'
// export const Type42 = 'MINDSET_FOR_IELTS-UNDERLINE_TYPE'
export const WATCH_VIDEO = 'WatchVideo';
export const VIDEO_AND_LIVEWORKSHEET = 'VIDEO_AND_LIVEWORKSHEET';
