import React, { Component } from 'react';
import { BsPlayFill } from 'react-icons/bs';
import ServerPlayer from 'react-player';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import { Button } from 'antd';

class WatchVideo extends Component {
  constructor(props) {
    super(props);

    // State variables
    this.state = {
      counterStatus: 'STOP', // Status of the counter
      isPlaying: false, // Determines if the video is playing
      isFullscreen: false, // Determines if the video is in fullscreen
    };

    this.durationVideo = 0; // Duration of the video (ms)
    this.elapsedTime = 0; // Thời gian đã xem video (ms)
    this.timerId = undefined;
    this.KEY_TIMER_LOCAL = window.location.pathname; //Dùng làm key local storage để kếm thời gian xem video
  }

  // Determine if the url is a YouTube url
  isYouTubeUrl = () => {
    const { videoUrl } = this.props;
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return regex.test(videoUrl);
  };

  // Player configuration
  playerConfig = {
    file: {
      attributes: {
        controlsList: 'nodownload',
      },
    },
  };

  componentDidMount() {
    // Listen for fullscreen changes
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
  }

  componentDidUpdate(prevProps) {
    // Reset state when video URL changes
    if (prevProps.videoUrl !== this.props.videoUrl) {
      this.setState({
        counterStatus: 'STOP',
      });
      this.elapsedTime = 0;
    }
  }

  componentWillUnmount() {
    localStorage.removeItem(this.KEY_TIMER_LOCAL);
    clearInterval(this.timerId);
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
  }

  //Đếm ngược thời gian khi cần yêu cầu xem hết video với cho next
  countDownSubmitVideo = (miliseconds) => {
    if (miliseconds) {
      // thời gian đã xem = thời gian video thì báo cho parent component
      this.timerId = setTimeout(() => {
        this.props.onWatchFullVideoTime();
      }, miliseconds - 1000); // - 1000 để làm thời gian 1s chênh lệch trong quá trình tính toán
    } else {
      if (!!this.timerId) {
        clearTimeout(this.timerId);
      }
    }
  };

  // Handle fullscreen change events
  handleFullscreenChange = () => {
    this.setState({
      isFullscreen: !!(document.fullscreenElement || document.webkitFullscreenElement),
    });
  };

  // Render the play icon for the video player
  playIcon = () => (
    <div
      style={{
        borderRadius: '50%',
        border: '2px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        paddingLeft: 8,
      }}
    >
      <BsPlayFill size={60} color={'white'} />
    </div>
  );

  onStart = () => {
    if (!!this.props.onWatchFullVideoTime) {
      //Hàm onWatchFullVideoTime được gọi khi thời gian xem video = thời gian của video
      const time = this.durationVideo - this.elapsedTime;
      this.countDownSubmitVideo(time);
    }
    this.setState({ counterStatus: 'START' });
    const timeNow = Date.now();
    localStorage.setItem(this.KEY_TIMER_LOCAL, timeNow);
  };

  onPause = () => {
    this.setState({ counterStatus: 'STOP' });
    const startTime = localStorage.getItem(this.KEY_TIMER_LOCAL);
    if (startTime) {
      // Tính khoảng thời gian đã xem
      const pauseTime = Date.now(); // Lấy thời gian hiện tại
      const elapsedTime = pauseTime - parseInt(startTime, 10); // Tính thời gian đã trôi qua (milliseconds)

      // Cộng thêm thời gian vào elapsedTime
      this.elapsedTime += elapsedTime;

      // Xóa thời gian bắt đầu khỏi localStorage
      localStorage.removeItem(this.KEY_TIMER_LOCAL);
    }
  };

  // Trả về kết quả
  getResults = () => {
    let milliseconds = this.elapsedTime;
    const startTime = localStorage.getItem(this.KEY_TIMER_LOCAL) ?? Date.now();
    if (startTime) {
      // Tính khoảng thời gian đã xem
      const pauseTime = Date.now(); // Lấy thời gian hiện tại
      const elapsedTime = pauseTime - parseInt(startTime, 10); // Tính thời gian đã trôi qua (milliseconds)
      // Cộng thêm thời gian vào elapsedTime
      milliseconds += elapsedTime;
    }
    const completePercent = (milliseconds && this.durationVideo) ? Math.floor((milliseconds / this.durationVideo) * 100) : 0;
    return {
      duration: this.durationVideo,
      elapsedTime: milliseconds,
      completePercent: completePercent,
    };
  };

  render() {
    const { videoUrl } = this.props;
    const { isPlaying } = this.state;

    return (
      <div>
        
        {this.isYouTubeUrl() ? (
          <ReactPlayer
            url={videoUrl}
            controls
            width={`calc(62vh * 16 / 9)`}
            height={'62vh'}
            style={{ border: '5px solid #163F6F' }}
            onDuration={(duration) => (this.durationVideo = Math.floor(duration * 1000))}
            onStart={this.onStart}
            onPlay={this.onStart}
            onPause={this.onPause}
          />
        ) : (
          <ServerPlayer
            // playing={isPlaying}
            controls
            url={videoUrl}
            width={`calc(62vh * 16 / 9)`}
            height={'62vh'}
            style={{ border: '5px solid #163F6F' }}
            playIcon={this.playIcon()}
            // onClickPreview={() => this.setState({ isPlaying: true })}
            // onEnded={() => this.setState({ isPlaying: false })}
            onDuration={(duration) => (this.durationVideo = Math.floor(duration * 1000))}
            onStart={this.onStart}
            onPlay={this.onStart}
            onPause={this.onPause}
            onEnded={this.onPause}
            config={this.playerConfig}
          />
        )}
      </div>
    );
  }
}

WatchVideo.propTypes = {
  onWatchFullVideoTime: PropTypes.func | undefined,
  videoUrl: PropTypes.string,
};

WatchVideo.defaultProps = {
  videoUrl: '',
};

export default WatchVideo;
