import { axiosAMES } from 'configs/api';
import { query } from 'helpers/QueryHelper';

const levels = [
  {
    level: 'Starters',
    title: 'Cấp độ Mầm Non và Lớp 1 - Starters',
  },
  {
    level: 'Movers',
    title: 'Cấp độ Lớp 2 và Lớp 3 - Movers',
  },
  {
    level: 'Flyers',
    title: 'Cấp độ Lớp 4 và Lớp 5 - Flyers',
  },
];

export function* getSessionsMyames({ classId, studentId, isTypeMix4Video = 0 }) {
  if (classId === '0') {
    const res = yield query('p_AMES247_Student_Stories_Results', { studentId });
    let result = levels;
    if (res && res?.length > 0) {
      result = levels.map((item) => {
        const findData = res.find((i) => i.storyGroupName === item.level);
        if (findData) {
          return {
            ...item,
            ...findData,
          };
        } else {
          return item;
        }
      });
    }
    return result;
  } else {
    const res = yield axiosAMES.get(`GetClassSession/${classId}/${studentId}/${isTypeMix4Video}`);

    if (res.data.message === 'OK') {
      res.data.results.className = res.data.className;
      return res.data.results;
    }

    if (res.data.message === 'Error') {
      return 'Error';
    }
  }
}
