import React from 'react';
import { Rate } from 'antd';
import * as functions from 'components/functions';
import RdIcon from 'components/functions/rdIcons';
import PropTypes from 'prop-types'
import classNames from 'classnames';
import { CardUI } from './CardUI';
import * as textQuestionTypes from '../../../QuestionModule/typesQuestion';
import { Col, Row } from 'reactstrap';

const Type15Modal = ({ detail, type }) => {

  if (!detail) return null;
  const studentChoices = JSON.parse(detail.studentChoices);

  const renderIcons = () => {
    const iconArray = RdIcon(type);
    return iconArray?.map((item, index) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          key={index}
          className="avatar avatar-sm rounded-circle"
          onClick={(e) => e.preventDefault()}
        >
          <img alt="..." src={item} />
        </a>
      );
    });
  };

  //type = 'text'| 'image'| 'sound'
  const renderCard = (index,type,item,checkAnswer) => {
    const result = studentChoices.pairs.left[index].id === studentChoices.pairs.right[index].id ? 1 : 0
    return (<CardUI key={index + type} type={type} data={item} result={checkAnswer && result} />);
  };

  const renderRow = (index) => {
    switch (type) {
      case textQuestionTypes.Type15: {
        return (
          <>
            <Col className="px-0">{renderCard(index,'text',studentChoices.pairs.left[index])}</Col>
            <Col className="text-white col-auto px-0">{arrowRender(index)}</Col>
            <Col className="px-0">{renderCard(index,'image',studentChoices.pairs.right[index],true)}</Col>
            <Col className="px-0">{renderCard(index,'image',studentChoices.pairs.left[index])}</Col>
          </>
        );
      }
      case textQuestionTypes.Type16: {
        return (
          <>
            <Col className="px-0">{renderCard(index,'sound',studentChoices.pairs.left[index])}</Col>
            <Col className="text-white col-auto px-0">{arrowRender(index)}</Col>
            <Col className="px-0">{renderCard(index,'text', studentChoices.pairs.right[index],true)}</Col>
            <Col className="px-0">{renderCard(index,'text', studentChoices.pairs.left[index])}</Col>
          </>
        );
      }
      case textQuestionTypes.Type17:
      case textQuestionTypes.Type17A: {
        return (
          <>
            <Col className="px-0">{renderCard(index,'sound',studentChoices.pairs.left[index])}</Col>
            <Col className="text-white col-auto px-0">{arrowRender(index)}</Col>
            <Col className="px-0">{renderCard(index,'image',studentChoices.pairs.right[index],true)}</Col>
            <Col className="px-0">{renderCard(index,'image',studentChoices.pairs.left[index])}</Col>
          </>
        );
      }
      default:
        return null;
    }
  };

  const arrowRender = (index) => {
    let score = [];
    const left = studentChoices.pairs.left;
    const right = studentChoices.pairs.right;
    const color =  left[index].id === right[index].id ? 'Green' : 'Red'
    const imageUrl = require(`assets/img/Arrow${color}.png`);
      return (
          <img style={{ width: 'min(60px,8vw)', aspectRatio : '1/1' }} src={imageUrl} alt="..." />
      );
  }

  return (
    <div className="modal-body text-center">
      <p>{renderIcons()}</p>
      <p className='text-red' style={{ fontSize: 18, fontWeight: '500' }}>Your Rate:</p>
      <Rate
        disabled
        value={functions.getStarRecord(detail.score)}
        allowHalf
      />
        <Row className="bg-gradient-secondary justify-content-md-center text-center align-items-center mb-2">
          <Col></Col>
          <Col className="text-white col-auto px-0 d-none">{arrowRender(0)}</Col>
          <Col></Col>
          <Col style={{fontSize: 'min(15px,2vw)'}}>Correct Answers</Col>
        </Row>
      {
        studentChoices.pairs.left.map((item,index) => (
          <Row key={index} className="bg-gradient-secondary justify-content-md-center text-center align-items-center mb-4">
          {renderRow(index)}
        </Row>))
      }

    </div>
  )
}

Type15Modal.propTypes = {
  detail: PropTypes.instanceOf(Object),
  type: PropTypes.string
}
export default Type15Modal