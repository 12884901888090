import NotData from 'components/Error/NotData';
import WrongData from 'components/Error/WrongData';
import Loading from 'components/Loading';
import { query } from 'helpers/QueryHelper';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Result from './Result';
import ResultsWSnoLogin from './ResultsWSnoLogin';

function ViewResultExercise() {
  const { classId, sessionId, logId, excerciseType } = useParams();
  const [resultData, setResultData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  React.useEffect(() => {
    if (excerciseType === 'IELTS') {
      query('p_AMES247_Student_GetAssignmentLogDetail_IELTS', {
        resultId: logId,
        classId: Number(classId),
      })
        .then((res) => {
          setResultData(res);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (excerciseType === 'LIVEWORKSHEET') {
      query('p_AMES247_Student_GetAssignmentLogDetail_LiveWorkSheet', {
        resultId: logId,
        classId: Number(classId),
      })
        .then((res) => {
          const results = res?.[0]?.logResult?.[0];
          setResultData({ ...results, jsonData: JSON.parse(results.jsonData) });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) return <Loading />;
  return (
    <div>
      {!resultData ? (
        <NotData />
      ) : excerciseType === 'LIVEWORKSHEET' ? (
        <ResultsWSnoLogin isLoading={isLoading} resultData={resultData} />
      ) : excerciseType === 'IELTS' ? (
        <Result resultsData={resultData} />
      ) : (
        <WrongData />
      )}
    </div>
  );
}

export default ViewResultExercise;
