/* eslint-disable react/prop-types */
import React, { useImperativeHandle } from 'react';
import WatchVideo from 'components/WatchVideo';
import { Card, CardBody, Container } from 'reactstrap';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import * as functions from 'components/functions';
import { SlidePropsType } from 'modules/QuestionModule/SlideQuestions/PropsType';
import { dynamicApiAxios } from 'configs/api';
import { query } from 'helpers/QueryHelper';

const VideoSlide = ({ studentData, data }, ref) => {
  const location = useLocation();
  const videoRef = React.createRef();

  const { studentId, sessionId, assignmentId, attachmentId, classId } = studentData;
  const { takeExamTime, asrId } = queryString.parse(location.search);
  const { id, fileType, fileName, fileUrl, questionId } = data;
  const videoUrl = fileUrl;

  // Handle moving to the next data
  const saveResults = () => {
    const results = videoRef.current.getResults();

    const parameters = {
      studentId,
      sessionId,
      assignmentId,
      questionEntityName: '[SmartEducation].[dbo].[t_AMES247_Files]',
      questionId,
      takeExamTime,
      appName: 'WEB_MY_AMES',
      duration: results.duration,
      classId,
      completePercentTypeVideo: 100,
      star:results.completePercent,
    };
    return query('p_AMES247_Student_Assignment_Logs_Insert_Mix4TypeVideo_v2', parameters);
  };

  // Sử dụng useImperativeHandle để expose function ra ngoài
  useImperativeHandle(ref, () => ({
    submit: () => {
      return saveResults();
    },
  }));

  return (
    <Container className="my-3 position-relative">
      <div className="d-flex align-items-center justify-content-center">
        <Card className="text-white text-center mt-2 mb-0" style={{ width: 'auto', height: 'auto' }}>
          <CardBody style={{ padding: 0 }}>
            <WatchVideo ref={videoRef} videoUrl={videoUrl} />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

VideoSlide.propTypes = SlidePropsType;

VideoSlide.defaultProps = {
  studentData: {},
  data: null,
};

export default React.forwardRef(VideoSlide);
