//Các key sử dụng để tránh sai text
const KEY_STORAGE_ASSIGNMENTS = 'KEY_STORAGE_ASSIGNMENTS';
const KEY_STORAGE_ASSIGNMENT_PARTS = 'KEY_STORAGE_ASSIGNMENT_PARTS';

//Hỗ trợ thao tác với local storage
function getItemLocalStorage(key) {
    const jsonValues = localStorage.getItem(key);
    const values = jsonValues ? JSON.parse(jsonValues) : undefined;
    return values;
}

function setItemLocalStorage(key, data) {
    const newValues = JSON.stringify(data);
    localStorage.setItem(key, newValues);
    return true;
}

function removeItemLocalStorage(key) {
    localStorage.removeItem(key);
}

export { 
    KEY_STORAGE_ASSIGNMENTS,KEY_STORAGE_ASSIGNMENT_PARTS,
    setItemLocalStorage, getItemLocalStorage, removeItemLocalStorage
 };
