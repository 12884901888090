import { useParams } from 'react-router-dom';

import ExcercisePage from 'components/CanvasToolPlus/ExcercisePage';
import Result from 'components/CanvasToolPlus/ExcercisePage/Result';
import NotData from 'components/Error/NotData';

import { RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import * as Colors from 'configs/color';
import { query } from 'helpers/QueryHelper';
import React from 'react';
import { useQuery } from 'react-query';
import { Container } from 'reactstrap';

const ResultsWSnoLogin = ({ isLoading, resultData }) => {
  const { classId, sessionId, logId } = useParams();
  const ref = React.useRef();

  const { data: Ames_sesion } = useQuery('p_API_MYAMES_GetSession', () =>
    query('p_API_MYAMES_GetSession', { SessionId: sessionId })
  );

  const RenderHeader = ({ course = '', session = '' }) => (
    <Container className={`header bg-${Colors.PRIMARY} pb-2`} fluid>
      <div className="header-body">
        <Row align="middle" gutter={5} style={{ padding: 24, color: 'white', fontWeight: 600 }}>
          <Col>
            <h2 className="h2 text-white mb-0">{`${resultData?.fullName}'S EXERCISE DETAILS`}</h2>
          </Col>
          <Col>
            <RightOutlined />
          </Col>
          <Col>
            <i className="fas fa-book-open" />
          </Col>
          <Col>
            <RightOutlined />
          </Col>
          <Col>
            <span style={{ fontSize: 17 }}>{course}</span>
          </Col>
          <Col>
            <RightOutlined />
          </Col>
          <Col>
            <span style={{ fontSize: 16 }}>{session}</span>
          </Col>
        </Row>
      </div>
    </Container>
  );

  return (
    <div>
      <RenderHeader course={resultData?.className} session={Ames_sesion?.[0]?.title} />
      <Result data={resultData?.jsonData?.result} />
      <hr style={{ margin: 1 }} />
      <div style={{ height: 'calc(100vh - 210px)', overflowY: 'auto' }}>
        {resultData ? (
          <>
            <ExcercisePage isVisibleResult={false} userAnswer={resultData} />
          </>
        ) : (
          <NotData />
        )}
      </div>
      <div>
        <hr style={{ margin: 1 }} />
        <Row justify="center" style={{ textAlign: 'end', padding: 25 }}>
          <div id="Footer-ExcercisePage-Audio" />
        </Row>
      </div>
    </div>
  );
};

export default ResultsWSnoLogin;
