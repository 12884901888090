import PropTypes from 'prop-types';

export const SlidePropsType = {
  studentData: {
    classId: PropTypes.number,
    studentId: PropTypes.number,
    sessionId: PropTypes.string,
    assignmentId: PropTypes.string,
    attachmentId: PropTypes.string,
  },
  data: PropTypes.object,
};
