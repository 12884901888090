import { Button, Result } from 'antd';
import React from 'react';

const title = 'Ames English';

function Page404() {
  document.title = title;
  return (
    <div className="main-content">
      <iframe
        src="/not_found.html" // Đường dẫn đến trang HTML ở pulic
        title="Ames English"
        width="100%"
        height="100%"
        style={{
          border: 'none',
          display: 'block', // Đảm bảo không có khoảng trắng nào xung quanh iframe
          width: '100vw',
          height: '100vh',
        }}
      />
    </div>
  );
}

export default Page404;
