import React from 'react';
import PropTypes from 'prop-types';
import { CardBody, CardHeader } from 'reactstrap';
import { Card, Col, Rate, Row } from 'antd';
import moment from 'moment';
import colors from 'constants/colors';
import { AiFillSchedule } from 'react-icons/ai';
import { LuAlarmClock } from 'react-icons/lu';
import { FaUsers } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaCircle } from 'react-icons/fa';
import { TiTimes } from 'react-icons/ti';

function CardItem({ data }) {
  const attendance = data.attendance ? JSON.parse(data.attendance) : null;
  const review = data.review ? JSON.parse(data.review) : null;

  return (
    <Card
     title={
      <Row justify="space-between" align="middle">
        <Col>
          <Row gutter={[12, 12]}>
            <Col>
              <img src={process.env.PUBLIC_URL + '/icons/calendar_icon.webp'} style={{ width: 45 }} alt="" />
            </Col>
            <Col style={{ fontWeight: 600 }}>
              <div style={{ color: colors.theme.default }}>Ames English</div>
              <div>
                <LuAlarmClock style={{ fontSize: '1rem' }} />
                &ensp;
                {moment(data.date).format('DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <strong style={{ color: colors.theme.default }}>
            <FaUsers style={{ fontSize: '1.2rem', color: 'green' }} /> &ensp;{data.className}
          </strong>
        </Col>
      </Row>}>
      
      <div>
        {!!attendance && (
          <>
            <div className="h2">
              <i className="far fa-check-square text-green"></i> ĐIỂM DANH
            </div>
            <div>
              <div>
                <b>Tình trạng:</b>{' '}
                {attendance[0].Attendance == 'COMAT' ? (
                  <>
                    <FaCheck className="text-green" /> Có mặt
                  </>
                ) : attendance[0].Attendance == 'NGHI' ? (
                  <>
                    <FaCircle className="text-warning text-bold"></FaCircle> Xin nghỉ
                  </>
                ) : attendance[0].Attendance == 'XINNGHI' ? (
                  <>
                    <TiTimes className="text-red text-bold"></TiTimes> Vắng mặt
                  </>
                ) : (
                  ''
                )}
              </div>
              {!!attendance[0]?.TimeIn && !!attendance[0]?.TimeOut && (
                <div>
                  <b>Thời gian:</b> {`${attendance[0].TimeIn.slice(11, 16)} - ${attendance[0].TimeOut.slice(11, 16)}`}
                </div>
              )}
            </div>
          </>
        )}
        <div className="h2">
          <i className="far fa-check-square text-green"></i> NỘI DUNG BÀI HỌC
        </div>
        <strong>{data.title}</strong>
        <div dangerouslySetInnerHTML={{ __html: data.content ?? '' }}>{}</div>

        <div>
          {!!review && (
            <>
              <div className="h2">
                <i className="far fa-check-square text-green"></i> ĐÁNH GIÁ
              </div>
              <div>
                <b>Điểm thưởng:</b> <Rate allowHalf value={review[0]?.Raiting} disabled />
              </div>
              <div>
                <b>Giáo viên:</b> {review[0]?.FullName}
              </div>
              {!!review[0]?.Comment && (
                <div>
                  <b>Nhận xét:</b> {review[0]?.Comment}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
}

CardItem.propTypes = {
  data: PropTypes.object,
};

export default CardItem;
