/* eslint-disable react/prop-types */
import { Button, Row, Tooltip } from 'antd';
import React from 'react';
import { BiChevronRightCircle } from 'react-icons/bi';
import { BsPlayFill } from 'react-icons/bs';
import ServerPlayer from 'react-player';
import ReactPlayer from 'react-player/lazy';
import { Card, CardBody, Container } from 'reactstrap';

const TypeWatchVideo = ({ studentId, questionIndex, takeExamTime, assignmentId, startRecord, onNext, question }) => {
  // State variables
  const [counterStatus, setCounterStatus] = React.useState('STOP'); // Status of the counter
  const [counterTimer, setCounterTimer] = React.useState(0); // Timer value
  const [durationVideo, setDurationVideo] = React.useState(0); // Duration of the video
  const [isPlaying, setIsPlaying] = React.useState(false); // Determines if the video is playing
  const [isFullscreen, setIsFullscreen] = React.useState(false); // Determines if the video is in fullscreen
  const [isCompleted, setIsCompleted] = React.useState(false); // Determines if the video is completed

  // Determine if the url is a youtube url
  const isYouTubeUrl = React.useMemo(() => {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return regex.test(question?.url);
  }, [question?.url]);

  // Player configuration
  const playerConfig = {
    file: {
      attributes: {
        controlsList: 'nodownload',
      },
    },
  };

  // Update the counter timer every second
  React.useEffect(() => {
    const timerId = setInterval(() => {
      // counterTimer không đồng bộ với thồi gian phát video nên trừ hao 10 giây để hiển hiện nút hoàn thành
      setCounterTimer(counterStatus === 'START' ? counterTimer + 1 : counterTimer);
      //Xem hết video mới được submit
      if (counterTimer !== 0 && durationVideo !== 0 && counterTimer > durationVideo - 10) {
        setIsCompleted(true);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [counterTimer, counterStatus, durationVideo]);

  // Reset all state variables when question is changed
  React.useEffect(() => {
    return () => {
      setCounterStatus('STOP');
      setCounterTimer(0);
      setIsCompleted(false);
    };
  }, [question]);

  // Handle fullscreen change events
  React.useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!(document.fullscreenElement || document.webkitFullscreenElement));
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, [isFullscreen]);

  // Handle moving to the next question
  const handleNext = () => {
    const param = {
      score: 100,
      notes: '',
      questionGuid: '',
      answerType: 'WATCH_VIDEO',
      duration: durationVideo * 1000,
      completePercentTypeVideo: 100,
      totalDurationVideo: durationVideo * 1000,
      studentChoice: JSON.stringify({}),
    };
    onNext(
      [
        {
          resultRecord: {
            score: 100,
            wordShows: [],
            recordUrl: '',
          },
          questionIndex,
        },
      ],
      param,
      false
    );
  };

  // Render the play icon for the video player
  const playIcon = () => (
    <div
      style={{
        borderRadius: '50%',
        border: '2px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        paddingLeft: 8,
      }}
    >
      <BsPlayFill size={60} color={'white'} />
    </div>
  );

  // Render the TypeWatchVideo component
  return (
    <Container className="my-3 position-relative">
      <div className="d-flex align-items-center justify-content-center">
        <Card className="text-white text-center mt-2 mb-0" style={{ width: 'auto', height: 'auto' }}>
          <CardBody style={{ padding: 0 }}>
            {isYouTubeUrl ? (
              <ReactPlayer
                url={question?.url}
                controls
                width={`calc(62vh * 16 / 9)`}
                height={'62vh'}
                style={{ border: '5px solid #163F6F' }}
                onDuration={(duration) => setDurationVideo(Math.floor(duration))}
                onStart={() => setCounterStatus('START')}
                onPlay={() => setCounterStatus('START')}
                onPause={() => setCounterStatus('STOP')}
              />
            ) : (
              <ServerPlayer
                playing={isPlaying}
                controls
                url={question.url}
                width={`calc(62vh * 16 / 9)`}
                height={'62vh'}
                style={{ border: '5px solid #163F6F' }}
                playIcon={playIcon()}
                onClickPreview={() => setIsPlaying(true)}
                onEnded={() => setIsPlaying(false)}
                onDuration={(duration) => setDurationVideo(Math.floor(duration))}
                onStart={() => setCounterStatus('START')}
                onPlay={() => setCounterStatus('START')}
                onPause={() => setCounterStatus('STOP')}
                config={playerConfig}
              />
            )}
          </CardBody>
        </Card>
      </div>
      <Row justify="end" style={{ marginTop: 20 }}>
        {isCompleted ? (
          <Button
            className="zoom"
            onClick={handleNext}
            type="primary"
            style={{ backgroundColor: '#022F63', borderRadius: 4 }}
          >
            <Row align="middle">
              Next &ensp; <BiChevronRightCircle style={{ fontSize: 18 }} />
            </Row>
          </Button>
        ) : (
          <i>Xem hết video để chuyển bài ( không tua, không xem nhanh)</i>
        )}
      </Row>
    </Container>
  );
};

export default TypeWatchVideo;
