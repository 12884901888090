import * as React from 'react';
import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash.flow';
import Listen from 'components/Listening';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ComponentProps = {
  type: PropTypes.oneOf(['text', 'image', 'sound']).isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
  moveCard: PropTypes.func
};

class CardUI extends React.Component {
  renderChild = () => {
    const { type, data } = this.props;
    switch (type) {
      case 'text':
        return <span style={{ display: '-webkit-box', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical'}}>{data.text}</span>;
      case 'image':
        return <img alt="..." src={data.imageUrl} style={{width: '100%',aspectRatio: '190/140'}} />;
      case 'sound':
        return (
          <Listen audioURL={data.soundUrl}>
            <i style={{ fontSize: 15 }} className="fas fa-volume-up"></i>
          </Listen>
        );
      default:
        return null;
    }
  };

  render() {
    const { type, isRight = true } = this.props;
    return (
        <Button style={{ minHeight: 'min(150px,20vw)', width: 'min(150px,20vw)', fontWeight: 'bold', fontSize: 'min(15px,2vw)', padding: '0.3rem 0.6rem' }} color={type === 'text' ? 'info' : undefined}>
          {this.renderChild()}
        </Button>
    );
  }
}

CardUI.propTypes = ComponentProps;

const cardSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    if (!component) return null;

    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) return;


    /** Only move card when cursor is move over 50% of item */
    // const cardNode = component.cardRef.current;
    // const hoverBoundingRect = cardNode?.getBoundingClientRect();

    // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // const clientOffset = monitor.getClientOffset();
    // const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
    // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
    /** End only move */

      props.moveCard?.(dragIndex, hoverIndex);

    monitor.getItem().index = hoverIndex;
  },
};

class DragCardUI extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
  }

  render() {
    const { connectDragSource, connectDropTarget } = this.props;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(connectDropTarget(<div ref={this.cardRef}><CardUI  {...this.props} /></div>)) // wrap div to parse native element to reactdnd
    );
  }
}

const DragCard = flow(
  DragSource('card', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget('card', cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(DragCardUI);

DragCard.propTypes = ComponentProps;

export { DragCard, CardUI };
